import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn from '../../layouts/TemplateColumn';
import { PageHeader, RelatedContent, PrimaryCallOut, ReferencesBlock, TextBlock, ResourceLibrary, AnchorLinks, DefinitionList } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import { jobCodes } from '../../util/data/jobCodes';
// import ResourceCard from '../../components/ResourceCard';
import ResourceOwlSilder from '../../components/ResourceOwlSilder';
import { Heading } from '../../util/typography';
import { createMarkup, stringToSlug } from '../../util/functions';
import styled from 'styled-components';
import PageSection from '../../components/layout/PageSection';
import "./hcp.css";

// SEO INFORMATION
const pageMeta = {
    title: `Resource Center for KEYTRUDA® (pembrolizumab) | HCP`,
    keywords: `resources, surgeons, patients, health care professionals`,
    description: `Health care professionals and surgeons can access downloadable resources, including case studies, to use and share with their patients during treatment with KEYTRUDA® (pembrolizumab).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/resources/hcp-and-patient-resources/","@type":"MedicalWebPage","name":"HCP & Patient Resources","description":"Below you will find downloadable guides and information to use during treatment with KEYTRUDA."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/resources/hcp-and-patient-resources/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico. "}`
    ]
}

const jobCode = jobCodes[31].jobCode;


const definitionsForSurgeons = `
    NSCLC = non&ndash;small cell lung cancer; TNBC = triple-negative breast cancer; RCC = renal cell carcinoma.
`;

const earlierStageIndicationsForSurgeons = [{
    items: [
        {
            title: 'NSCLC',
            content: '<ul><li>KEYTRUDA is indicated for the treatment of patients with resectable (tumors ≥4 cm or node positive) non&ndash;small cell lung cancer (NSCLC) in combination with platinum-containing chemotherapy as neoadjuvant treatment, and then continued as a single agent as adjuvant treatment after surgery.</li><li>KEYTRUDA, as a single agent, is indicated for adjuvant treatment following resection and platinum-based chemotherapy for adult patients with stage IB (T2a ≥4 cm), II, or IIIA non&ndash;small cell lung cancer (NSCLC).</li>'
        },
        {
            title: 'TNBC',
            content: '<ul><li>KEYTRUDA is indicated for the treatment of patients with high-risk early-stage triple-negative breast cancer (TNBC) in combination with chemotherapy as neoadjuvant treatment, and then continued as a single agent as adjuvant treatment after surgery.</li>'
        },
        {
            title: 'RCC',
            content: '<ul><li>KEYTRUDA is indicated for the adjuvant treatment of patients with renal cell carcinoma (RCC) at intermediate-high or high risk of recurrence following nephrectomy, or following nephrectomy and resection of metastatic lesions.</li>'
        },
        {
            title: 'Melanoma',
            content: '<ul><li>KEYTRUDA is indicated for the adjuvant treatment of adult and pediatric (12 years and older) patients with stage IIB, IIC, or Ill melanoma following complete resection.</li>'
        }
    ]
}];

const anchorLinkModelData = [
    { label: 'Featured Resources' },
    { label: 'For Health Care Professionals (HCPs)', labelTextOverride: 'For Health Care <span class="line-break"></span> Professionals (HCPs)'},
    { label: 'For Surgeons' },
    { label: 'For Your Patients' }
]

const relatedContent = [
    {
        section: 'RESOURCES',
        link: {
            url: '/efficacy/',
            label: 'Efficacy'
        }
    },
    {
        section: 'RESOURCES',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    }, {
        section: 'RESOURCES',
        link: {
            url: '/resources/key-you-patient-support-program/',
            label: 'Patient Support Program'
        }
    },
]


const resourceCard = [

    {
        image: () => (
            <img src="/static/images/hcp-patient-resources/biomaker.png" alt="Download a Biomarker Eligibility Brochure for Treatment With KEYTRUDA® (pembrolizumab)" width={700} height={400} />
        ),
        title: 'Biomarker Eligibility Brochure',
        body: "A guide to biomarker testing and cut points for relevant tumor types and indications.",
        url: '/static/pdf/HCP_Biomarker_Eligibility_Brochure.pdf',
        buttonLabel: 'Download Brochure',
    },
    {
        image: () => (
            <img src="/static/images/hcp-patient-resources/patient-checklist.png" alt="Download Patient Monitoring Checklist for KEYTRUDA® (pembrolizumab)" width={700} height={400} />
        ),
        title: 'Patient Monitoring Checklist',
        body: "Identify and monitor some of the signs and symptoms associated with adverse reactions related to treatment with KEYTRUDA.",
        url: '/static/pdf/Patient_Monitoring_Checklist.pdf',
        buttonLabel: 'Download Checklist',
        isButtonDisable: 'true'
    },
    {
        image: () => (
            <img src="/static/images/hcp-patient-resources/guide-care-team.png" alt="Download a Guide to Help Patients Understand the Role of Each Specialist on a Cancer Care Team" width={700} height={400} />
        ),
        title: 'A Guide to Your Cancer Care Team',
        body: "This guide can help patients understand the role of each specialist on their cancer support team and the importance of taking an active part in their own care.",
        url: '/static/pdf/A_Guide_to_Your_Cancer_Care_Team.pdf',
        buttonLabel: 'Download Guide',
    },
];

const HCPitem =
    [
        // {

        //     image: () => (
        //         <img src="/static/images/hcp-patient-resources/Martin-Patient-Profile.png" alt="Download a Case Study of a Hypothetical Patient Diagnosed With Pathological Prognostic Stage IVA (T3N2M0) p16-negative Oropharyngeal Squamous Cell Carcinoma Treated With KEYTRUDA® (pembrolizumab)" width={700} height={400} />
        //     ),
        //     title: 'Clinical Case Study: Hypothetical Patient with Recurrent, Metastatic Head and Neck Squamous Cell Carcinoma',
        //     body: "Review details of this hypothetical patient's clinical case.",
        //     url: '/static/pdf/HCP_Patient_Profile_with_Recurrent_HNSCC.pdf',
        //     buttonLabel: 'Download Case Study',
        // },
        // {

        //     image: () => (
        //         <img src="/static/images/hcp-patient-resources/Diane-Patient-Profile.png" alt="Download a Case Study of a Hypothetical Patient Diagnosed With Stage IVA (T1N2M0) Laryngeal Squamous Cell Carcinoma Treated With KEYTRUDA® (pembrolizumab)" width={700} height={400} />
        //     ),
        //     title: 'Clinical Case Study: Hypothetical Patient with Unresectable, Recurrent Head and Neck Squamous Cell Carcinoma',
        //     body: "Read this clinical case to learn more about this hypothetical patient's history, prior oncology treatment, and adverse event management plan.",
        //     url: '/static/pdf/HCP_Patient_Profile_with_Unresectable_HNSCC.pdf',
        //     buttonLabel: 'Download Case Study',
        // },
        // {

        //     image: () => (
        //         <img src="/static/images/hcp-patient-resources/Diane-Patient-Profile2.png" alt="Download Select NCCN Clinical Practice Guidelines for Head and Neck Cancers" width={700} height={400} />
        //     ),
        //     title: 'Select Guidelines for Patients with Recurrent, Unresectable, or Metastatic Head and Neck Cancers',
        //     body: "Review what these guidelines recommend for certain patients with head and neck cancers.",
        //     url: '/static/pdf/HCP_NCCN_Resource_HNSCC.pdf',
        //     buttonLabel: 'Download Guidelines',
        // },
        {

            image: () => (
                <img src="/static/images/hcp-patient-resources/Nephritis.png" alt="Download a Guide on How to Manage Immune-Mediated Nephritis During Treatment With KEYTRUDA® (pembrolizumab)" width={700} height={400} />
            ),
            title: 'Hypothetical Patient Profile Management of Immune-Mediated Nephritis',
            body: "Review a hypothetical patient profile that informs the monitoring and management of immune-mediated nephritis.",
            url: '/static/pdf/Hypothetical_Patient_Profile_Management_of_Immune_Mediated_Nephritis.pdf',
            buttonLabel: 'Download Profile',
        },
        {
            image: () => (
                <img src="/static/images/hcp-patient-resources/Pneumonitis.png" alt="Download a Guide on How to Manage Immune-Mediated Pneumonitis During Treatment With KEYTRUDA® (pembrolizumab)" width={700} height={400} />
            ),
            title: 'Hypothetical Patient Profile Management of Immune-Mediated Pneumonitis',
            body: "Review a hypothetical patient profile that informs the monitoring and management of immune-mediated pneumonitis.",
            url: '/static/pdf/Hypothetical_Patient_Profile_Management_of_Immune_Mediated_Pneumonitis.pdf',
            buttonLabel: 'Download Profile',
        },
        {
            image: () => (
                <img src="/static/images/hcp-patient-resources/Hypothyroidism.png" alt="Download a Guide on How to Manage Immune-Mediated Hypothyroidism During Treatment With KEYTRUDA® (pembrolizumab)" width={700} height={400} />
            ),
            title: 'Hypothetical Patient Profile Management of Immune-Mediated Hypothyroidism',
            body: "Review a hypothetical patient profile that informs the monitoring and management of immune-mediated hypothyroidism.",
            url: '/static/pdf/Hypothetical_Patient_Profile_Management_of_Immune_Mediated_Hypothyroidism.pdf',
            buttonLabel: 'Download Profile',
        },
        {

            image: () => (
                <img src="/static/images/hcp-patient-resources/biomaker.png" alt="Biomarker Eligibility Brochure" width={700} height={400} />
            ),
            title: 'Biomarker Eligibility Brochure',
            body: "A guide to biomarker testing and cut points for relevant tumor types and indications.",
            url: '/static/pdf/HCP_Biomarker_Eligibility_Brochure.pdf',
            buttonLabel: 'Download Brochure',
        },
        {

            image: () => (
                <img src="/static/images/hcp-patient-resources/Biomarker-Eligibility-Brochure1.png" alt="Download Information on Adverse Reactions With KEYTRUDA® (pembrolizumab)" width={700} height={400} />
            ),
            title: 'Signs and Symptoms of Select Immune-Mediated Adverse Reactions (IMARs) and Other Adverse Reactions',
            body: "Signs and symptoms of select immune-mediated adverse reactions (IMARs) and other adverse reactions for KEYTRUDA.",
            url: '/static/pdf/Signs_and_Symptoms_of_Select_IMARs_and_Other_ARs_Brochure.pdf',
            buttonLabel: 'Download Checklist',
        },
        {

            image: () => (
                <img src="/static/images/hcp-patient-resources/patient-checklist.png" alt="Patient Monitoring Checklist" width={700} height={400} />
            ),
            title: 'Patient Monitoring Checklist',
            body: "Identify and monitor some of the signs and symptoms associated with adverse reactions related to treatment with KEYTRUDA.",
            url: '/static/pdf/Patient_Monitoring_Checklist.pdf',
            buttonLabel: 'Download Checklist',
            isButtonDisable: 'true'
        },
        {

            image: () => (
                <img src="/static/images/hcp-patient-resources/guide-urologists.png" alt="Download an Urologist Guide to Select Indications, MOA, and Dosing Information for KEYTRUDA® (pembrolizumab)" width={700} height={400} />
            ),
            title: 'KEYTRUDA: A Treatment Guide for Urologists',
            body: "A guide to select indications, mechanism of action, dosing options and modifications, preparation/administration, and monitoring/management of adverse reactions.",
            url: '/static/pdf/US-KEY-08326.pdf',
            buttonLabel: 'Download Brochure',
            isButtonDisable: 'true'
            //   disabled:true,
        },
        {
            blank: true,
        },
        {
            blank: true,
        }
    ]

const SurgeonItem = [
    {
        image: () => (
            <img src="/static/images/Biomarker_Eligibility_Brochure.png" alt="Download a Surgeon Brochure to Identify Whether Certain Patients With NSCLC, TNBC, RCC, and Melanoma are Eligible for KEYTRUDA® (pembrolizumab)" width={700} height={400} />
        ),
        title: 'Surgeon Brochure for KEYTRUDA',
        body: "A resource to help you identify whether certain patients with NSCLC, TNBC, RCC, or melanoma are eligible for KEYTRUDA, learn about the mechanism of action for KEYTRUDA, and get tips on collaborating with oncologists.",
        url: '/static/pdf/Surgeon_Brochure_For_Keytruda.pdf',
        buttonLabel: 'Download Brochure',
    },
    {
        image: () => (
            <img src="/static/images/Endpoint_Brochure.png" alt="Download a Surgeon End Points Brochure for KEYTRUDA® (pembrolizumab)" width={700} height={400} />
        ),
        title: 'KEYTRUDA: End Points Brochure',
        body: "Learn more about the end points and study designs used in the clinical trials evaluating KEYTRUDA for the treatment of certain types of NSCLC, TNBC, RCC, and melanoma.",
        url: '/static/pdf/End_Points_Brochure.pdf',
        buttonLabel: 'Download Brochure',
    },
    {
        image: () => (
            <img src="/static/images/Signs_and_Symptoms_Brochure.png" alt="Signs and Symptoms of Select IMARs and Other Adverse Reactions Brochure" width={700} height={400} />
        ),
        title: 'Signs and Symptoms of Select IMARs and Other Adverse Reactions Brochure',
        body: "In this resource, you can review certain signs and symptoms of select IMARs and other adverse reactions that patients should report. The list presented in the brochure is not exhaustive.",
        url: '/static/pdf/Signs_and_Symptoms_of_Select_IMARs_and_Other_ARs_Brochure.pdf',
        buttonLabel: 'Download Brochure',
    },
    {
        image: () => (
            <img src="/static/images/Understanding_the_Mechanism_of_Action_for_KEYTRUDA.png" alt="Download a Guide to Mechanism Of Action for KEYTRUDA® (pembrolizumab)" width={700} height={400} />
        ),
        title: <>Understanding the Mechanism of Action for KEYTRUDA®<br />(pembrolizumab)</>,
        body: "A guide to help understand the mechanism of action for KEYTRUDA.",
        url: '/static/pdf/Understanding_the_Mechanism_of_Action_for_KEYTRUDA.pdf',
        buttonLabel: 'Download Brochure',
    },
    {
        blank: true,
    },
    {
        blank: true,
    }
    /*
    {
        image: () => (
            <img src="/static/images/hcp-patient-resources/Mechanism.png" alt="ADT HCP Martin Patient Profile" width={700} height={400} />
        ),
        title: 'ADT HCP Martin Patient Profile',
        body:"Review Martin's clinical case—a hypothetical patient with recurrent, metastatic head and neck squamous cell carcinoma.",
        url: '/static/pdf/US-OHN-01974_ADT_HCP_Martin_Patient_Profile_PDF_HNSCC_L07_Functional_Secure.pdf',
        buttonLabel: 'Download Profile',
    },
    {
        image: () => (
            <img src="/static/images/hcp-patient-resources/Mechanism.png" alt="ADT HCP Diane Patient Profile" width={700} height={400} />
        ),
        title: 'ADT HCP Diane Patient Profile',
        body:"Review Diane's clinical case—a hypothetical patient with recurrent, metastatic head and neck squamous cell carcinoma.",
        url: '/static/pdf/US-OHN-01975_ADT_HCP_Diane_Patient_Profile_PDF_HNSCC_L11_Functional_Secure.pdf',
        buttonLabel: 'Download Profile',
    },
    */
]
const NursesItem =
    [
        {

            image: () => (
                <img src="/static/images/hcp-patient-resources/pdf-image.png" alt="pdf-image" width={700} height={400} />
            ),
            title: 'Borem ipsum dolor sit amet consectetur',
            body: "Norem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
            url: '#',
            buttonLabel: 'Download Resource',
        },
        {

            image: () => (
                <img src="/static/images/hcp-patient-resources/pdf-image.png" alt="pdf-image" width={700} height={400} />
            ),
            title: 'Yorem ipsum dolor sit amet consectetur',
            body: "Torem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
            url: '#',
            buttonLabel: 'Download Resource',
        },
        {

            image: () => (
                <img src="/static/images/hcp-patient-resources/pdf-image.png" alt="pdf-image" width={700} height={400} />
            ),
            title: 'Porem ipsum dolor sit amet consectetur',
            body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
            url: '#',
            buttonLabel: 'Download Resource',
        },

    ]
const PatientItem =
    [
        {

            image: () => (
                <img src="/static/images/hcp-patient-resources/patient-guide-care-team.png" alt="Download a Guide to Help Patients Understand the Role of Each Specialist on a Cancer Care Team" width={700} height={400} />
            ),
            title: 'A Guide to Your Cancer Care Team',
            body: "This guide can help patients understand the role of each specialist on their cancer support team and the importance of taking an active part in their own care.",
            url: '/static/pdf/A_Guide_to_Your_Cancer_Care_Team.pdf',
            buttonLabel: 'Download Guide',
        },
        {

            image: () => (
                <img src="/static/images/hcp-patient-resources/patient-support-community.png" alt="Download a Guide on How to Build Your Support Community During Cancer Treatment" width={700} height={400} />
            ),
            title: 'Build Your Support Community Guide',
            body: "This guide can help patients build a strong support community, and includes lists for them to fill out on who can help them with their needs.",
            url: '/static/pdf/Build_Your_Support%20_Community_Guide.pdf',
            buttonLabel: 'Download Guide',
        },
        {

            image: () => (
                <img src="/static/images/hcp-patient-resources/patient-doctor-conversation-guide-start.png" alt="Download a Guide on How to Have a Better Conversation With Your Doctor Before, During and After the Appointment" width={700} height={400} />
            ),
            title: 'Doctor Conversation Guide: Questions to Ask Before Starting Treatment',
            body: "A guide for patients and caregivers with information and discussion points about starting treatment with KEYTRUDA.",
            url: '/static/pdf/Doctor_Conversation_Tool_Guide_Questions_to_Ask_Before_Starting%20_Treatment.pdf',
            buttonLabel: 'Download Guide',
        },
        {

            image: () => (
                <img src="/static/images/hcp-patient-resources/patient-doctor-conversation-guide-while.png" alt="Download a Guide on What to Discuss With Your Doctor Throughout Cancer Treatment" width={700} height={400} />
            ),
            title: 'Doctor Conversation Guide: Questions to Ask While on Treatment',
            body: "This guide for patients includes information and important questions to ask during the entire treatment journey, from just starting out to when treatment is finished.",
            url: '/static/pdf/Doctor_Conversation_Guide_Questions_to_Ask_While_on_Treatment.pdf',
            buttonLabel: 'Download Guide',
        },
        {

            image: () => (
                <img src="/static/images/hcp-patient-resources/patient-food.png" alt="Download a Guide on Managing Meals, Communication, and Confidence During Cancer Treatment" width={700} height={400} />
            ),
            title: 'Food, Communication, and Confidence Guide',
            body: "Information for patients and caregivers about managing meals during treatment, and how to deal with feelings and social interactions that revolve around food.",
            url: '/static/pdf/Food_Communication_and_Confidence_Guide.pdf',
            buttonLabel: 'Download Guide',
        },
        {
            blank: true,
        }

    ]

const pageReferences = [
    {
        label: "1.",
        text: "Berardi R, Morgese F, Rinaldi S, et al. Benefits and limitations of a multidisciplinary approach in cancer patient management. <em>Cancer Manag Res.</em> 2020;12:9363-9374. doi:10.2147/CMAR.S220976"
    },
    {
        label: "2.",
        text: "Surgical oncologist. City of Hope. Updated April 7, 2023. Accessed July 13, 2023. https://www.cancercenter.com/physician-directory/types-oncologists/surgical-oncologists"
    },


]
const resourceLibraryItemsForPatients = [
    {
        label: 'Surgeon Brochure for KEYTRUDA',
        url: '/static/pdf/US-KEY-08326.pdf',
        resourceBodyText: 'A resource to help you identify patients eligible for KEYTRUDA in NSCLC, TNBC, RCC, or melanoma, learn about the mechanism of action for KEYTRUDA, and get tips on collaborating with oncologists.',
        image: () => (
            <StaticImage src="../../assets/keytruda-surgeon-brochure.png" placeholder="TRACED_SVG" alt="Download a Surgeon Brochure to Identify Eligible Patients for KEYTRUDA® (pembrolizumab) in NSCLC, TNBC, RCC, and Melanoma" />
        ),
    },
    {
        label: 'KEYTRUDA: End Points Brochure',
        url: '/static/pdf/End_Points_Brochure.pdf',
        resourceBodyText: 'Learn more about the end points and study designs used in the clinical trials for KEYTRUDA in NSCLC, TNBC, RCC, and melanoma.',
        image: () => (
            <StaticImage src="../../assets/key-endpoints-brochure.png" placeholder="TRACED_SVG" alt="Download a Surgeon End Points Brochure for KEYTRUDA® (pembrolizumab) in NSCLC, TNBC, RCC, and Melanoma" />
        ),
    },
    {
        label: 'Signs and Symptoms of Select Immune-Mediated Adverse Reactions (IMARs) and Other Adverse Reactions Brochure',
        url: '/static/pdf/Signs_and_Symptoms_of_Select_IMARs_and_Other_ARs_Brochure.pdf',
        resourceBodyText: 'In this resource, you can review some select signs and symptoms of select IMARs and other adverse reactions that patients should report. The list presented in the brochure is not exhaustive.',
        image: () => (
            <StaticImage src="../../assets/multidisciplinary-care-coordination-resource.png" placeholder="TRACED_SVG" alt="Download a Resource to Review Signs and Symptoms of Select Immune-Mediated Adverse Reactions (IMARs) and Other Adverse Reactions Brochure for KEYTRUDA® (pembrolizumab)" />
        ),
    },
]

const PageSectionTitle = styled(Heading.H4)`
text-align: center;

@media screen and (max-width: 665px) {
        font-size: 20px;
        line-height: 26px;
}

`;

const AnchorTarget = styled.span`
    display: block;
    height: 0;
    overflow: hidden;
    line-height:none;
    transform: translateY(-155px); //manage offset for fully-expanded sticky nav
`;


/* Break to a new line at screen widths below 768px */


const Page = ({ location }) => {
    const initializeOwlCarousel = (sliderId) => {
        const script = document.createElement('script');
        script.textContent = `
          $(document).ready(function() {
            $('#${sliderId}').owlCarousel({
              items: 3,
              loop: false,
              slideBy: 3,
              nav: true,
              dots: true,
              mouseDrag: false,
              touchDrag: true,
              center:false,
              onTranslate: function(event) {
                        var totalItems = event.item.count;
                        var currentIndex = event.item.index;
                        if("${sliderId}" == "professionals" || "${sliderId}" == "surgeon" ){
                        console.log("professionals")
                         // Check if the current item is the last one
                        if (currentIndex === totalItems - 3) {
                        // Disable swiping for the last item
                        $('#${sliderId}').addClass('disable-swiping');
                        } else {
                        // Re-enable swiping
                        $('#${sliderId}').removeClass('disable-swiping');
                        }
                        }
                         if("${sliderId}" == "patient"){
                         // Check if the current item is the last one
                        if (currentIndex === totalItems - 2) {
                        // Disable swiping for the last item
                        $('#${sliderId}').addClass('disable-swiping');
                        } else {
                        // Re-enable swiping
                        $('#${sliderId}').removeClass('disable-swiping');
                        }
                        }                       
                    },
              responsive: {
                1024: {
                  items: 3,
                  slideBy: 3,
                },
                992: {
                  items: 3,
                  slideBy: 3,
                },
                991: {
                  items: 2,
                  slideBy: 2,
                },
                767:{
                  items:2,
                  slideBy: 2,
                },               
                414:{
                 items:1,
                 center:true,
                 stagePadding: 40,
                },
                390:{
                items:1,
                center:true,
                stagePadding: 30,
                },
                375:{
                items:1,
                center:true,
                stagePadding: 20,
                }

              },
            });

        const $carousel =  $('#patient');          
        const $carouselProf =  $('#professionals');    
        const $carouselSur = $('#surgeon');   

        let startX${sliderId};
      
      // Track the starting X position for swipe direction detection
        $carousel.on('touchstart', function(e) {
        startX${sliderId} = e.originalEvent.touches[0].pageX;
      });

      // Determine swipe direction and prevent default swipe action if needed
      $carousel.on('touchmove', function(e) {
        const endX = e.originalEvent.touches[0].pageX;
        const swipeDirection = startX${sliderId} - endX;

        // Only disable swipe for right-to-left swipes on the last item
        if ($carousel.hasClass('disable-swiping') && swipeDirection > 0) {
          e.preventDefault();
          e.stopPropagation();
        }
      });

    $('#professionals .owl-next, #professionals .owl-prev, #professionals .owl-dot').attr('data-design-module', 'hcp_resources').attr('data-design-label', 'carousel_click');
    $('#surgeon .owl-next, #surgeon .owl-prev, #surgeon .owl-dot').attr('data-design-module', 'surgeon_resources').attr('data-design-label', 'carousel_click');
    $('#patient .owl-next, #patient .owl-prev, #patient .owl-dot').attr('data-design-module', 'patient_resources').attr('data-design-label', 'carousel_click');

      // Track the starting X position for swipe direction detection
        $carouselProf.on('touchstart', function(e) {
        startX${sliderId} = e.originalEvent.touches[0].pageX;
      });

      // Determine swipe direction and prevent default swipe action if needed
      $carouselProf.on('touchmove', function(e) {
        const endX = e.originalEvent.touches[0].pageX;
        const swipeDirection = startX${sliderId} - endX;

        // Only disable swipe for right-to-left swipes on the last item
        if ($carouselProf.hasClass('disable-swiping') && swipeDirection > 0) {
          e.preventDefault();
          e.stopPropagation();
        }
      });



    // Track the starting X position for swipe direction detection
        $carouselSur.on('touchstart', function(e) {
        startX${sliderId} = e.originalEvent.touches[0].pageX;
      });

      // Determine swipe direction and prevent default swipe action if needed
      $carouselSur.on('touchmove', function(e) {
        const endX = e.originalEvent.touches[0].pageX;
        const swipeDirection = startX${sliderId} - endX;

        // Only disable swipe for right-to-left swipes on the last item
        if ($carouselSur.hasClass('disable-swiping') && swipeDirection > 0) {
          e.preventDefault();
          e.stopPropagation();
        }
      });

});
`;

        document.head.appendChild(script);
    };
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '/js/jquery.min.js'; // Replace with the path to your script
        script.async = true;
        script.onload = function () {
            const scriptOwl = document.createElement('script');
            scriptOwl.src = '/js/owl.carousel.min.js'; // Replace with the path to your script
            scriptOwl.async = true;
            scriptOwl.onload = function () {
                // Initialize the carousel here

                initializeOwlCarousel("featured");
                initializeOwlCarousel("professionals");
                initializeOwlCarousel("patient");
                initializeOwlCarousel("surgeon");
                initializeOwlCarousel("nurses");
            };
            document.head.appendChild(scriptOwl);
        };
        document.head.appendChild(script);
    }, []);

    // AT/screenshots hook to disable all Tertiary Buttons and resourceBodyText links
    const [allResourcesDisabled, setAllResourcesDisabled] = useState(false);
    useEffect(() => {
        const disableHandler = (event) => {
            if (event.data.action === 'disableAllResources') {
                setAllResourcesDisabled(true);
            }
        };

        window.addEventListener("message", disableHandler);
        return () => {
            window.removeEventListener("message", disableHandler);
        };
    }, []);

    return (
        <div className='hcp-page-layout'>

            <DefaultLayout pageMeta={pageMeta} location={location} jobCode={jobCode}>
                <div data-template-name="template-c">
                    {/* Begin Intro Column */}
                    <TemplateColumn id="template-c-intro">
                        <PageHeader title="Resource Center" />

                        <PageSection
                            bgColor="cloud"
                            className= "hcp-anchor-links"
                        >
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                    </TemplateColumn>
                    {/* End Intro Column */}
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-c-main" >
                        <PageSection centerTitle >
                        <AnchorTarget id={stringToSlug(anchorLinkModelData[0].label)}/>
                            <PageSectionTitle >{anchorLinkModelData[0].label}</PageSectionTitle>
                            <ResourceOwlSilder items={resourceCard} sliderId={"featured"} dataDesignModule={"featured_content"} />
                        </PageSection>

                        <PageSection bgColor="cloud" className="professionals" >
                        <AnchorTarget id={stringToSlug(anchorLinkModelData[1].label)}/>
                            <PageSectionTitle >For HCPs</PageSectionTitle>
                            <ResourceOwlSilder items={HCPitem} sliderId={"professionals"} dataDesignModule={"hcp_resources"} />
                        </PageSection>

                        {/* <PageSection title="Resources for Surgeons" bgColor="cloud">
                    <TextBlock>
                            <p>As a surgeon, you play a critical role in developing comprehensive treatment plans for patients. The resources below can help you collaborate with your patient’s medical oncologist and multidisciplinary team (MDT) to determine if your patient is eligible for neoadjuvant and adjuvant therapy or adjuvant therapy only, together with surgery.<sup>1,2</sup></p>
                
                        </TextBlock>
                        <ResourceLibrary items={resourceLibraryItemsForPatients} allResourcesDisabled={allResourcesDisabled} />
                    </PageSection> */}

                        <PageSection list='surgeon'>
                          <AnchorTarget id={stringToSlug(anchorLinkModelData[2].label)}/>
                            <PageSectionTitle >{anchorLinkModelData[2].label}</PageSectionTitle>
                            <DefinitionList sections={earlierStageIndicationsForSurgeons} subhead='Earlier-Stage Indications' maxWidth='860px' list='surgeon' />
                            <ResourceOwlSilder items={SurgeonItem} sliderId={"surgeon"} dataDesignModule={"surgeon_resources"} />
                        </PageSection>
                        {/* <PageSection bgColor="cloud">
                    <HeadingH3
                        as='H3'
                        dangerouslySetInnerHTML={createMarkup("For Nurses")}
                    />
                        <ResourceOwlSilder items={NursesItem} sliderId={"nurses"} />
                    </PageSection> */}
                        <PageSection bgColor="cloud">
                        <AnchorTarget id={stringToSlug(anchorLinkModelData[3].label)}/>
                            <PageSectionTitle >{anchorLinkModelData[3].label}</PageSectionTitle>
                            <ResourceOwlSilder items={PatientItem} sliderId={"patient"} dataDesignModule={"patient_resources"} />
                        </PageSection>

                        <PageSection bgColor="gradient">
                            <PrimaryCallOut
                                title="Ask Merck"
                                content="Merck is committed to helping you get your product questions answered. Connect with a Merck Associate now."
                                primaryAction={{ label: 'Get Connected', url: 'https://www.askmerck.com/?_ga=2.198129772.257841330.1718726615-1680932473.1709246246' }}
                            />
                        </PageSection>
                        <PageSection bgColor='keytrudaGreen'>
                            <RelatedContent items={relatedContent} />
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}
                </div>
            </DefaultLayout>
        </div>
    );
};

export default Page;
