import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, DataTable, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock } from '../../../components';
import { StaticImage } from 'gatsby-plugin-image';
import cervicalTable from "../../../assets/cervical-safety-158-e-mobile-v2.svg"
import { jobCodes } from '../../../util/data/jobCodes';
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-158: Selected Safety Data and Adverse Reactions`,
    keywords: `keytruda second-line monotherapy advanced cervical cancer adverse reactions`,
    description: `Learn about the immune-mediated adverse reactions for KEYTRUDA® (pembrolizumab) and the selected safety data from KEYNOTE-158 (Cohort E).`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/cervical-cancer-second-line-monotherapy/","@type":"ApprovedIndication","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA, as a single agent, is indicated for the treatment of patients with recurrent or metastatic cervical cancer with disease progression on or after chemotherapy whose tumors express programmed death ligand 1 (PD⁠-⁠L1) [combined positive score (CPS) ≥1] as determined by an FDA-approved test."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/cervical-cancer-second-line-monotherapy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

// image alts - so they do not repeat in mobile & desktop
const cervicalSafetyAlt = "Adverse Reactions Occurring in ≥10% of Patients Receiving KEYTRUDA® (pembrolizumab) With Cervical Cancer in KEYNOTE-158"

//from /src/util/data/indications.js
const indicationId = 24;
const jobCode = jobCodes[24].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: 'FIGO 2014 Stage III-IVA Cervical Cancer', url: '/efficacy/cervical-cancer-combination-chemoradiotherapy/' },
                { text: '1L Combo Therapy Persistent, Recurrent, or Metastatic Cervical Cancer', url: '/efficacy/cervical-cancer-first-line-combination-therapy/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
                        ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Incidence of Selected Immune-Mediated Adverse Reactions' }, 
    { label: 'Management of Selected Immune-Mediated Adverse Reactions' }, 
    {label: 'Selected Safety Data from KEYNOTE-158 (Cohort E)'}
]

const safetyTable1Footnotes = [
    {
        label: "c.",
        text: "Graded per NCI CTCAE v4.0."
    },
    {
        label: "d.",
        text: "Includes asthenia, fatigue, lethargy, malaise."
    },
    {
        label: "e.",
        text: "Includes breast pain, cancer pain, dysesthesia, dysuria, ear pain, gingival pain, groin pain, lymph node pain, oropharyngeal pain, pain, pain of skin, pelvic pain, radicular pain, stoma site pain, toothache"
    },
    {
        label: "f.",
        text: "Includes edema peripheral, peripheral swelling"
    },
    {
        label: "g.",
        text: "Includes arthralgia, back pain, musculoskeletal chest pain, musculoskeletal pain, myalgia, myositis, neck pain, non-cardiac chest pain, pain in extremity"
    },
    {
        label: "h.",
        text: "Includes colitis, diarrhea, gastroenteritis"
    },
    {
        label: "i.",
        text: "Includes abdominal discomfort, abdominal distension, abdominal pain, abdominal pain lower, abdominal pain upper"
    },
    {
        label: "j.",
        text: "Includes epistaxis, hematuria, hemoptysis, metrorrhagia, rectal hemorrhage, uterine hemorrhage, vaginal hemorrhage"
    },
    {
        label: "k.",
        text: " Includes bacterial pyelonephritis, pyelonephritis acute, urinary tract infection, urinary tract infection bacterial, urinary tract infection pseudomonal, urosepsis"
    },
    {
        label: "l.",
        text: "Includes cellulitis, clostridium difficile infection, device-related infection, empyema, erysipelas, herpes virus infection, infected neoplasm, infection, influenza, lower respiratory tract congestion, lung infection, oral candidiasis, oral fungal infection, osteomyelitis, pseudomonas infection, respiratory tract infection, tooth abscess, upper respiratory tract infection, uterine abscess, vulvovaginal candidiasis"
    },
    {
        label: "m.",
        text: "Includes dermatitis, drug eruption, eczema, erythema, palmar-plantar erythrodysesthesia syndrome, rash, rash generalized, rash maculo-papular"
    },
    
];

const safetyTable1Definitions = "UTI = urinary tract infection; NCI CTCAE = National Cancer Institute Common Terminology Criteria for Adverse Events; Q3W = every 3 weeks."

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId}  jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label}>
                            <TextBlock>
                                <p>Among the 98 patients with cervical cancer in Cohort E of KEYNOTE&#8288;-&#8288;158, the median duration of exposure to KEYTRUDA was 2.9 months (range: 1 day to 22.1 months). </p>
                                <p>In KEYNOTE&#8288;-&#8288;158, KEYTRUDA was discontinued due to adverse reactions in 8% of 98 patients with recurrent or metastatic cervical cancer. </p>
                                <p>Serious adverse reactions occurred in 39% of patients receiving KEYTRUDA; the most frequent included:</p>
                                <ul>
                                    <li>Anemia (7%)</li> 
                                    <li>Fistula (4.1%)</li> 
                                    <li>Hemorrhage (4.1%)</li>
                                    <li>Infections [except urinary tract infections] (4.1%)</li>
                                </ul>
                            </TextBlock>
                            <DataTable 
                                mobileImage={() => <img src={cervicalTable} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={cervicalSafetyAlt}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/cervical-safety-158-e-XL-v2.png" alt={cervicalSafetyAlt}/>}
                                title="Adverse Reactions Occurring in ≥10% of Patients With Cervical Cancer in KEYNOTE&#8288;-&#8288;158" 
                                footnotes={safetyTable1Footnotes} 
                                definitions={safetyTable1Definitions}
                            />
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}

                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
