import React, { useState, useEffect } from 'react';
import "/static/css/owl.carousel.min.css";
import "/static/css/owl.theme.default.min.css";
import "./resourceOwlSlider.css";
import Card from '../ResourceCard/Card';



const ResourceOwlSilder = ({items,sliderId, dataDesignModule, isButtonDisable}) => {
    
    return(
        <>
<div id={sliderId} className="owl-carousel owl-theme resource-card-slider" data-design-module={dataDesignModule}>

       { items && items.map((item,index) =>{
            return(
                <div className='item'>
                <Card {...item} rowKey={1} colKey={index} rowTier={3} isButtonDisable={item.isButtonDisable}  />
                </div>
            )
        })}
    </div>

    
        </>
    )
}

export default ResourceOwlSilder;
