import React from 'react';
import styled from 'styled-components';
import DefaultLayout from '../layouts/DefaultLayout';
import TemplateColumn from '../layouts/TemplateColumn';
import { PageHeader, PageSection, TieredCTAGrid } from '../components';
import { jobCodes } from '../util/data/jobCodes';
import * as CTA from '../util/CTA';
import { Heading } from '../util/typography';

// SEO INFORMATION
const pageMeta = {
    title: `Not Found | KEYTRUDA® (pembrolizumab) | Health Care Professionals`,
    keywords: ``,
    description: `The page you're looking for cannot be found. Please check back at a later date or visit other pages for KEYTRUDA® (pembrolizumab).`
}

const jobCode = jobCodes[0].jobCode;

const ButtonContainer = styled.div`
    margin-top: 38px !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Title = styled(Heading.H3)`
    text-align: center;
`;

const SitemapButton = styled(CTA.PrimaryButton)`
    a {
        display: block;
        margin: 38px auto 0;
    }
`;

const tieredCTARows = [
    {
        type: '33/33/33',
        rowTier: '2',
        items: [
            {
                image: () => (
                    <img src="/static/images/badge-safety.png" alt="" width={60} height={60} />
                ),
                category: 'Safety',
                title: 'Treatment Monitoring & Management',
                url: '/safety/monitoring-managing-adverse-reactions/',
                buttonLabel: 'View More Information',
            },
            {
                image: () => <img src="/static/images/badge-clipboard.png" alt="" width={60} height={60} />,
                category: 'The Merck Access Program',
                title: 'Patient Access & Reimbursement Support',
                url: 'https://www.merckaccessprogram-keytruda.com/',
                buttonLabel: 'Learn About Patient Support',
            }
        ],
    },
];

const Page = ({ location }) => {

    return (
        <>
            <DefaultLayout jobCode={jobCode} pageMeta={ pageMeta } location={ location } >
                <div data-template-name="template-c">
                    {/* Begin Intro Column */}
                    <TemplateColumn id="template-c-intro">
                        <PageHeader title="404 Error" />
                        <PageSection bgColor="cloud">
                            <Title>The page you are looking for cannot be found</Title>
                            <ButtonContainer>
                                <SitemapButton label="View Site Map" url="/sitemap/" />
                            </ButtonContainer>
                        </PageSection>
                        <PageSection bgColor="mist">
                            <TieredCTAGrid rows={tieredCTARows} />
                        </PageSection>
                    </TemplateColumn>
                    {/* End Intro Column */}
                </div>
            </DefaultLayout>
        </>
    );
};

export default Page;
