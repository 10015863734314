import React from 'react';
import styled from 'styled-components';
import { Heading, Text } from '../../util/typography';
import * as CTA from '../../util/CTA';
import * as colors from '../../util/colors';
import {componentTheme, createMarkup} from '../../util/functions';
import ComponentWrapper from '../layout/ComponentWrapper';
import Link from 'gatsby-link';

const ContentContainer = styled.div`
    // padding: 10px;
    text-align: center;
    margin: 0 -10px;
`;
const ContentHeader = styled(Heading.H3)`
    display: block;
    margin-bottom: 10px;
`;
const CardWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    --multiplier: calc(640px - 100%);
`;
const CardLink = styled.span``;

const CardLinkText = styled(Heading.H5)`
    color: ${colors.blueberry};
    transition: 0.3s ease all;
`;

const Card = styled(Link).attrs(props => ({
    'data-design-category': props.dataDesignCategory || props['data-design-category'] || 'content_link_vd'
}))`
    text-decoration: none;
    flex: 1 1 0;
    flex-basis: calc(var(--multiplier) * 999);
    text-align: left;
    margin: 10px;
    padding: 30px 20px;
    border-radius: 8px;
    cursor: pointer;
    background-color: ${colors.white};
    transition: all 0.3s ease;

    &:hover {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.16);

        ${CardLinkText} {
            color: ${colors.blueberryDark};
        }
    }
`;
const CardEyebrow = styled(Text.Eyebrow)`
    display: block;
    margin-bottom: 4px;
    color: ${colors.slate};
`;

const RelatedContent = props => {
    let theme = componentTheme(true, props.sectionTheme, props.theme);
    return (
        
        <ComponentWrapper>
            <ContentContainer>
                <ContentHeader as='H2' theme={theme}>{props.headingTitle?props.headingTitle:"You Might Also Be Interested In"}</ContentHeader>
                <CardWrapper>
                    {/* @todo Use conditional chaining */}
                    {!!props.items &&
                        props.items.map((item, i)=> (
                            <Card key={i} {...CTA.setPropsFromURL(item.link.url)} >
                                <CardEyebrow>{item.section}</CardEyebrow>
                                <CardLink>
                                    <CardLinkText dangerouslySetInnerHTML={createMarkup(item.link.label)} />
                                </CardLink>
                            </Card>
                        ))}
                </CardWrapper>
            </ContentContainer>
        </ComponentWrapper>
    );
};

export default RelatedContent;
