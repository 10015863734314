import styled from 'styled-components';
import { between } from 'polished';

const ComponentWrapper = styled.div`
    max-width: ${props => props.maxWidth || '992px'} !important;
    margin: 30px auto;

    @media (min-width: 320px) and (max-width: 1032px) {
        margin: ${between('30px', '40px', '320px', '1032px')} auto;
    }
    
    @media (min-width: 320px) and (max-width: 1023px){
    max-width: ${props => props.list === 'surgeon' ? '100%' : '992px'} !important;
}

    @media (min-width: 1033px) {
        margin: 40px auto;
    }
`;

export default ComponentWrapper;
