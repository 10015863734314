import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyDesignOverview, StudyDesignArms, StudyCopyBlock } from '../index';
import TextBlock from '../../TextBlock';

const CERVICAL = () => (
    
    <ComponentWrapper>
        <StudyDesignOverview title="Study Overview" description="A multicenter, nonrandomized, open-label, multicohort trial (n=98)" data={[
            [
                {
                    title: 'Patient Population',
                    description: `
                        <p>Patients with recurrent or metastatic cervical cancer</p>
                        <p>The trial excluded patients with autoimmune disease or a medical condition that required immunosuppression.</p>
                    `,
                    type: 'box-gradient'
                }
            ]
        ]} />
    
        <StudyDesignArms 
            title="Study Arms" 
            arms={[
                {
                    title: "KEYTRUDA",
                    data: [
                        [
                            {
                                title: 'Treatment',
                                description: `200&nbsp;mg administered intravenously every 3 weeks (Q3W)`,
                                type: 'box-green'
                            }
                        ],
                        [
                            {
                                type: 'arrow-green'
                            }
                        ],
                        [
                            {
                                title: 'Treatment Duration',
                                description: `Treatment continued until disease progression<sup>b</sup> or unacceptable toxicity, or up to 24 months`
                            }
                        ]
                    ]
                }
            ]} 
             footnotes={[
                {
                    label:'b.',
                    text: `Patients with initial radiographic disease progression could receive additional doses of treatment during confirmation of progression unless disease progression was symptomatic, was rapidly progressive, required urgent intervention, or occurred with a decline in performance status.`
                }
            ]}
        />

        <TextBlock>
            <p>Assessment of tumor status was performed every 9 weeks for the first 12 months, and every 12 weeks thereafter.</p>
        </TextBlock>
    
        <StudyCopyBlock 
            title="End Points"
            description={` 
                <p><strong>Major Efficacy Outcome Measures:</strong></p>
                <ul>
                    <li>ORR (according to RECIST 1.1, as assessed by BICR)<sup>c</sup>
                    <li>DOR</li>
                <ul>
            `} 
        />
    
        <StudyCopyBlock 
            title="Patient Population Details"
            description={`
                <p>Among the 98 patients in Cohort E, 77 (79%) had tumors that expressed PD&#8288;-&#8288;L1 (CPS ≥1) and had received at least 1 prior line of chemotherapy in the metastatic setting. PD&#8288;-&#8288;L1 status was determined using the IHC 22C3 pharmDx kit.</p>
                
                <p><strong>Patient characteristics (n=77):</strong></p>

                <ul>
                    <li>Median age (range): 45 years (27–75)</li>
                    <li>White 81%</li>
                    <li>Asian 14%</li>
                    <li>Black 3%</li>
                    <li>1 line of prior therapy<sup>d</sup>: 35%</li>
                    <li>2 or more lines of prior therapy<sup>d</sup>: 65%</li>
                    <li>Squamous cell carcinoma: 92%</li>
                    <li>Adenocarcinoma: 6%</li>
                    <li>Adenosquamous: 1%</li>
                    <li>ECOG PS 0: 32%</li>
                    <li>ECOG PS 1: 68%</li>
                    <li>M1 disease: 95%</li>
                    <li>Recurrent disease: 5%</li>
                <ul>
                
            `} 
            footnotes={[
                {
                    label:'c.',
                    text: `Modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ.`
                },
                {
                    label:'d.',
                    text: `In the recurrent or metastatic setting.`
                }
            ]}
            definitions={`
                IHC = immunohistochemistry; ECOG PS = Eastern Cooperative Oncology Group performance status; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors version 1.1; BICR = blinded independent central review.
            `} 
        />

    </ComponentWrapper>
);

export default CERVICAL;
