import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyDesignOverview, StudyDesignArms, StudyCopyBlock } from '../index';

const CERVICAL = () => (

    <ComponentWrapper>

        <StudyDesignOverview
            title="Study Overview"
            description="A phase 3 multicenter, randomized, double blind, placebo-controlled study of KEYTRUDA + chemotherapy with or without bevacizumab for patients with persistent, recurrent, or metastatic cervical cancer in the first line (N=617).<sup>1</sup>"
            data={[
            [
                {
                    title: 'Patient Population',
                    description: `
                        <p>Included patients with persistent, recurrent, or first line metastatic cervical cancer, regardless of tumor PD&#8288;-&#8288;L1 expression status, who had not been treated with chemotherapy except when used concurrently as a radio-sensitizing agent.</p>
                        <p>Excluded patients with autoimmune disease that required systemic therapy within 2 years of treatment or patients with a medical condition that required immunosuppression.</p>
                    `,
                    type: 'box-gradient',
                    colspan: 2
                }
            ],
            [
                {
                    type: 'arrow-gray',
                    colspan: 2
                }
            ],
            [
                {
                    title: 'Randomized<sup>g</sup> (1:1) (N=617)',
                    colspan: 2
                }
            ],
            [
                {
                    type: 'arrow-gray',
                },
                {
                    type: 'arrow-gray',
                }
            ],
        ]} />
        <StudyDesignArms title="Study Arms" arms={[
            {
                title: "KEYTRUDA + chemotherapy<sup>a</sup> ± bevacizumab (n=308)<sup>1,i</sup>",
                data: [
                    [
                        {
                            title: `<div>Treatment<sup>h,j</sup></div>
                                    <div>KEYTRUDA 200&nbsp;mg IV on day 1 Q3W</div>`,
                            description: [
                                {
                                    text: `Investigator's choice of chemotherapy:`,
                                    textCenter: true,
                                    conditional: 'and',
                                },
                                {
                                    text: `Paclitaxel 175&nbsp;mg/m<sup>2</sup>`,
                                    textCenter: true,
                                },
                                {
                                    text: `Cisplatin 50&nbsp;mg/m<sup>2</sup> ± bevacizumab 15&nbsp;mg/kg`,
                                    conditional: 'and',
                                    textCenter: true
                                },
                                {
                                    text: `Paclitaxel 175 mg/m<sup>2</sup>`,
                                    conditional: 'or',
                                    textCenter: true
                                },
                                {
                                    text: `Carboplatin AUC 5&nbsp;mg/mL/min ± bevacizumab 15&nbsp;mg/kg`,
                                    conditional: 'and',
                                    textCenter: true
                                },
                            ],
                            type: 'box-multi-color-green'
                        }
                    ],
                    [
                        {
                            type: 'arrow-green'
                        }
                    ],
                    [
                        {
                            title: 'Treatment Duration<sup>k</sup>',
                            description: `Treatment with
                            KEYTRUDA
                            continued until
                            RECIST
                            v1.1-defined
                            progression of
                            disease,
                            unacceptable
                            toxicity, or a
                            maximum of 24
                            months`
                        }
                    ],
                ]
            },
            {
                title: "Placebo + chemotherapy<sup>a</sup> ± bevacizumab (n=309)<sup>1</sup>",
                data: [
                    [
                        {
                            title: `<div>Treatment<sup>h,j</sup></div>
                                    <div>Placebo IV on day 1 Q3W</div>`,
                            description: [
                                {
                                    text: `Investigator's choice of chemotherapy:`,
                                    textCenter: true,
                                    conditional: 'and',
                                },
                                {
                                    text: `Paclitaxel 175&nbsp;mg/m<sup>2</sup>`,
                                    textCenter: true,
                                },
                                {
                                    text: `Cisplatin 50&nbsp;mg/m<sup>2</sup> ± bevacizumab 15&nbsp;mg/kg`,
                                    conditional: 'and',
                                    textCenter: true
                                },
                                {
                                    text: `Paclitaxel 175&nbsp;mg/m<sup>2</sup>`,
                                    conditional: 'or',
                                    textCenter: true
                                },
                                {
                                    text: `Carboplatin AUC 5&nbsp;mg/mL/min ± bevacizumab 15&nbsp;mg/kg`,
                                    conditional: 'and',
                                    textCenter: true
                                }
                            ],
                            type: 'box-multi-color-gray'
                        }
                    ],
                ]
            }
        ]} />

        <StudyCopyBlock
            description={`
                <p>Assessment of tumor status was performed every 9 weeks for the first year, followed by every 12 weeks thereafter.</p>
                <p>Of the 617 enrolled patients, 548 patients (89%) had tumors expressing PD&#8288;-&#8288;L1 with a CPS ≥1.</p>
                <p>Efficacy was assessed in 273 patients in the KEYTRUDA + chemotherapy +/- bevacizumab study arm vs 275 patients in the placebo + chemotherapy +/- bevacizumab study arm.</p>
           
            `}
        />

        <StudyCopyBlock
            title="End Points"
            description={`
                <p>Main efficacy outcome measures:</p>
                <ul>
                    <li>Overall survival (OS)</li>
                    <li>Progression-free survival (PFS)<sup>l</sup></li>
                </ul>
                <p>Additional efficacy outcome measures:</p>
                <ul>
                    <li>Objective response rate (ORR)<sup>m</sup></li>
                    <li>Duration of response (DOR)<sup>m</sup></li>
                </ul>
            `}
        />

        <StudyCopyBlock
            title="Baseline Characteristics in Patients With PD&#8288;-&#8288;L1 CPS ≥1 (n=548)"
            description={`
                <p>Median age (range): 51 years (22–82)</p>
                <ul>
                    <li>Age ≥65: 16%</li>
                </ul>

                <p>Race:</p>
                <ul>
                    <li>White: 59%</li>
                    <li>Asian: 18%</li>
                    <li>American Indian or Alaska Native: 6%</li>
                    <li>Black: 1%</li>
                </ul>

                <p>Ethnicity:</p>
                <ul>
                    <li>Hispanic or Latino: 37%</li>
                </ul>

                <p>ECOG PS:</p>
                <ul>
                    <li>Score of 0: 56%</li>
                    <li>Score of 1: 43%</li>
                </ul>

                <p>Received bevacizumab as part of study treatment: 63%</p>

                <p>Histology:</p>
                <ul>
                    <li>Squamous cell carcinoma: 75%</li>
                    <li>Adenocarcinoma: 21%</li>
                    <li>Adenosquamous: 5%</li>
                </ul>

                <p>Metastatic disease at diagnosis: 32%</p>
                <p>At study entry, had metastatic disease only: 21%</p>
                <p>At study entry, had persistent or recurrent disease with or without distant metastases: 79%</p>
                <ul>
                    <li>Received prior chemoradiation only: 39%</li>
                    <li>Received prior chemoradiation plus surgery:  17%</li>
                </ul>
            `}
            footnotes={[
                {
                    label:'a.',
                    text: `Chemotherapy (paclitaxel and cisplatin or paclitaxel and carboplatin).`
                },
                {
                    label:'g.',
                    text: `Stratification Factors: metastatic status at initial diagnosis, investigator decision to use bevacizumab, and PD-L1 status (CPS <1 vs CPS 1 to <10 vs CPS ≥10).`
                },
                {
                    label:'h.',
                    text: `All study medications were administered as an intravenous infusion.`
                },
                {
                    label: "i.",
                    text: "Of the 308 patients in the KEYTRUDA arm, 1 patient did not receive KEYTRUDA.<sup>1</sup>"
                },
                {
                    label:'j.',
                    text: `All study treatments were administered on day 1 of each 3-week treatment cycle. Cisplatin could be administered on day 2 of each 3-week treatment cycle.`
                },
                {
                    label:'k.',
                    text: `Administration of KEYTRUDA was permitted beyond RECIST-defined disease progression if the patient was clinically stable and considered to be deriving clinical benefit by the investigator.`
                },
                {
                    label:'l.',
                    text: `Assessed by investigator according to RECIST v1.1, modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ.`
                },
                {
                    label:'m.',
                    text: `According to RECIST v1.1, as assessed by investigator.`
                },
            ]}
            definitions={`
                AUC = area under the curve; ECOG PS = Eastern Cooperative Oncology Group performance status; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors version 1.1; Q3W = every 3 weeks.
            `}
        />


    </ComponentWrapper>
);

export default CERVICAL;
