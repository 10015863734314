import React, { useState } from 'react';
import styled from 'styled-components';
import * as CTA from '../../util/CTA';
import * as colors from '../../util/colors';
import { between } from 'polished';
import { Heading, Text } from '../../util/typography';
import { createMarkup } from '../../util/functions';
import "./card.css";

const classNamePrefix = 'resource-card';

// The dollar sign prevents cardprefix from being added to Container as an HTML attribute
const Container = styled.a.attrs(props => ({
    className: `${props.$cardPrefix}-card-container${props.$disabled ?" disabled":''}${props.$blank ? " blank":""}`
}))`
    min-width: 280px;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
    background: white;
    transition: 0.3s ease all;
    flex-grow: 1;
    &:hover {
        box-shadow:0 0px 10px 5px rgb(38 38 38 / 10%);
          .custom-border{
          border-color:transparent;
    }
        [class^='Card__ImageWrapper']{
        border-color:#e0ede2;
        border-bottom-color:${colors.pebble};
        }
    }
         &.disabled{
        filter: grayscale(1);
        pointer-events:none;
        position: relative;
        cursor:unset;
        border: 1px solid ${colors.pebble};
        .custom-border{
      border:none;
    }
        [class^='Card__ImageWrapper']{
        border-top:none;
        border-left:none;
        border-right:none;
        border-bottom-color:${colors.pebble};
        }

    &:hover {
    pointer-even
    box-shadow:unset;
      .custom-border{
      border:none;
    }
        [class^='Card__ImageWrapper']{
        border-top:none;
        border-left:none;
        border-right:none;
        border-bottom-color:${colors.pebble};
        }

    }

    &::before{
    content: "Coming Soon";
    color:#fff;
    background-color:#444;
    padding:8px;
    border: 1px solid #BEBEBE;
    border-top-left-radius:8px;
    border-bottom-right-radius:8px;
    position:absolute;
    top:0;
    left:0;
    z-index:1;
    }
    [class^="Card__ImageWrapper"]{
    filter: blur(2px);
    }
        }
    &.blank{
    visibility: hidden;
    }
`;

const ImageWrapper = styled.div.attrs(props => ({
    className: `${props.cardPrefix}-image-wrapper`
}))`
    pointer-events: none; //resolves issue where hovering over image 'stops' onMouseOver for the Card
   background-image:url('/static/images/hcp-patient-resources/bckgnd.png');
   width: 100%;
    min-height: 143px;
        border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
        background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid ${colors.pebble};
    border-bottom: 1px solid #BEBEBE;
    display: flex;
    align-items: flex-end;
    & img {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        width: 73% !important;
        margin:0 auto;
        height: auto;
        
        @media (max-width:1024px){
        width: 49% !important;
        }
        @media (max-width:991px){
        width: 68% !important;
        }
        @media (max-width:767px){
        width: 78% !important;
        }
       
    }
        @media (max-width:1024px){
        width: 100%;
        }
       
`;

const ContentWrapper = styled.div.attrs(props => ({
    className: `${props.cardPrefix}-content-wrapper`
}))`
    display: flex;
    flex-direction: column;
    ${props => props.verticalCenter && `justify-content: center;`}
    align-items: flex-start;
    
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(100% - 100px);
    text-align: left;
`;

const Eyebrow = styled(Text.Eyebrow)`
    color: ${colors.slate};
    margin-bottom: ${between('6px', '10px', '320px', '1032px')};

    @media (max-width: 320px) {
        margin-bottom: 6px;
    }

    @media (min-width: 1032px) {
        margin-bottom: 10px;
    }
`;

const Title = styled(Heading.H5).attrs(props => ({
    className: `${props.cardPrefix}-title-text`
}))`
    padding:20px 20px 0;
    ${props => props.rowTier !== '3' && `
        max-width: max(60%, 22ch);
    `}
`;

const Body = styled(Text.Body).attrs(props => ({
    className: `${props.cardPrefix}-body-text`
}))`
    padding:0 20px;
    margin-top: 6px;
    color:#262626;
    display:block;
`

const LinkContainer = styled.div`
    ${props => !props.verticalCenter && `margin-top: auto;`}
    pointer-events: none;
    color: gray;
    > span{
        margin:0px;
        padding:0 20px;
        > span{           
        padding:20px 0;
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        align-items: stretch;
    }
`;

const PrimaryButton = styled(CTA.PrimaryButton).attrs(props => ({
    className: `${props.cardPrefix}-button`
}))`
    margin-top: ${between('20px', '30px', '320px', '1032px')};
    margin-bottom: ${between('12px', '22px', '320px', '1032px')};

    @media (max-width: 320px) {
        margin-top: 20px;
        margin-bottom: 12px;
    }

    @media (min-width: 1032px) {
        margin-top: 30px;
        margin-bottom: 22px;
    }
`;

const TertiaryButton = styled(CTA.TertiaryButton).attrs(props => ({
    className: `${props.cardPrefix}-button hkpEOs`
}))`
    margin-top: 3px;
    margin-bottom: ${between('0px', '3.5px', '320px', '1032px')};
    disabled: true;

    @media (max-width: 320px) {
        margin-bottom: 0px;
    }

    @media (min-width: 1032px) {
        margin-bottom: 3.5px;
    }

    ${props => props.rowTier === '2' && `
        margin-top: ${between('13px', '3px', '320px', '1032px')};

        @media (max-width: 320px) {
            margin-top: 13px;
        }

        @media (min-width: 1032px) {
            margin-top: 3px;
        }
    `}
`;

const BadgeWrapper = styled.div`
    margin: 0 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Badge = styled(Text.Eyebrow).attrs(props => ({
    className: `${props.cardPrefix}-badge`
}))`
    font-size: 13px;
    color: white;
    background:
        radial-gradient(circle at 16% 5%,
            ${colors.blueberry} 0%,
            ${colors.keytrudaGreenWebForBanner} 70%,
            ${colors.keytrudaGreenWebForBanner} 85%);
    padding: 8px 16px 7px;
    border-radius: 4px;

    ${props => props.verticalCenter
        ? 'margin-top: 15px;'
        : `margin-top: clamp(0px, ${between('0px', '22px', '420px', '580px')}, 22px);`
    }

    margin-bottom: clamp(0px, ${between('0px', '7px', '420px', '580px')}, 7px);
`;

//@TODO need to resolve alt tag for image

const Card = props => {
    const { rowKey, colKey, disabled=false, blank=false, isButtonDisable } = props;
    const cardPrefix = `${classNamePrefix}-r${rowKey + 1}-c${colKey + 1}`;
    const [isCardHovered, setIsCardHovered] = useState(false);
    const onCardMouseEnter = () => {
        setIsCardHovered(true);
    };

    const onCardMouseLeave = () => {
        setIsCardHovered(false);
    };
    const Button =  TertiaryButton;
    // Use the CTA's href and target props if we need to open an internal link in a new tab (/prescribing-information/)
    const conditionalCTAProps = props.href 
        ? { href: props.href, target: props.target }
        : {};

    return (
        <Container {...CTA.setPropsFromURL(!isButtonDisable && props.url)} {...conditionalCTAProps} $cardPrefix={cardPrefix} $disabled={disabled} $blank={blank} onMouseEnter={onCardMouseEnter} onMouseLeave={onCardMouseLeave} data-design-category="button_vd">
            <ContentWrapper cardPrefix={cardPrefix} rowTier={props.rowTier} span="col-primary" verticalCenter={props.verticalCenter} $disabled='true'>
                {props.image && 
                    <ImageWrapper cardPrefix={cardPrefix}>
                        {typeof props.image === 'function' && props.image()}
                    </ImageWrapper>
                }
                {props.badge &&
                    <BadgeWrapper>
                        <Badge verticalCenter={props.verticalCenter} cardPrefix={cardPrefix} dangerouslySetInnerHTML={createMarkup(props.badge)} />
                    </BadgeWrapper>
                }
                {/* {props.category && <Eyebrow>{props.category}</Eyebrow>} */}
                <div className='custom-border'>

                <Title cardPrefix={cardPrefix} rowTier={props.rowTier}>{props.title}</Title>
                {props.body && <Body cardPrefix={cardPrefix}>{props.body}</Body>}
                <LinkContainer verticalCenter={props.verticalCenter} >
                    <Button
                        cardPrefix={cardPrefix}
                        rowTier={props.rowTier}
                        forwardedAs="span"
                        label={props.buttonLabel}
                        url={props.url}
                         parentHovered={isButtonDisable === 'true' ? 'none' : isCardHovered}
                        {...props.buttonProps}
                        disabled={isButtonDisable}
                    />
                </LinkContainer>
                </div>
                    {/* <TertiaryButton  label={props.buttonLabel} url={props.url} parentHovered={isCardHovered} disabled={disabled || allResourcesDisabled}/> */}
            </ContentWrapper>
        </Container>
    );
};

export default Card;
