import React, { useEffect } from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import {
    PageSection,
    CustomHomepageGrid,
    PrimaryCallOut,
    FilterProvider,
} from '../components';
import { jobCodes } from '../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYTRUDA® (pembrolizumab) | Indications and Efficacy Data for Health Care Professionals`,
    keywords: `keytruda, pembrolizumab`,
    description: `Health care professionals may find information about FDA-approved indications for KEYTRUDA® (pembrolizumab) along with KEYNOTE clinical trials and treatment guidelines.`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/","@type":"Drug","proprietaryName":"KEYTRUDA®","nonProprietaryName":"pembrolizumab","manufacturer":"Merck"}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/","@type":"MedicalWebPage","name":"A Key to More Possibilities for Treating Your Patients","description":"KEYTRUDA Is Approved to Treat 17 Types of Advanced Cancer. One indication is for the treatment of patients with unresectable or metastatic melanoma."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/","@type":"Website","name":"Keytrudahcp.com"}`
    ]
}

const jobCode = jobCodes[0].jobCode;

const cards = {
    'pi': {
        image: () => <img src="/static/images/badge-bookmark.png" alt="" width={60} height={60} />,
        title: 'Interactive Prescribing Information',
        href: '/interactivepi/',
        target: '_blank',
        buttonLabel: 'View Prescribing Information',
    },
    'dosing': {
        image: () => <img src="/static/images/badge-iv.png" alt="" width={60} height={60} quality={90} />,
        category: 'Dosing & Preparation',
        title: 'Dosing Options',
        url: '/dosing/options/',
        buttonLabel: 'View Dosing Details',
    },
    'safety': {
        image: () => <img src="/static/images/badge-safety.png" alt="" width={60} height={60} quality={90} />,
        category: 'Safety',
        title: 'Selected Adverse Reactions',
        url: '/safety/adverse-reactions/',
        buttonLabel: 'View More Information',
    },
    'resources': {
        image: () => <img src="/static/images/badge-info2.png" alt="" width={60} height={60} quality={90} />,
        category: 'Resources',
        title: 'Download Guides and Support Information',
        url: '/resources/hcp-and-patient-resources/',
        buttonLabel: 'View HCP & Patient Resources',
    },
    'combo': {
        title: 'Another Combination Treatment With KEYTRUDA® (pembrolizumab)',
        body:'Learn about another important indication for a combination treatment for your appropriate patients.',
        url: 'https://www.keytrudalenvimahcp.com/',
        buttonLabel: 'Visit Site',
    },
    'pd-l1': {
        category: 'Biomarker Testing',
        title: 'PD-L1 Testing & Scoring',
        body: 'Biomarker status can be a road map for personalizing treatment.',
        url: '/biomarker-testing/pd-l1/',
        buttonLabel: 'View Testing & Scoring',
    },
};


const tieredCTAData = {
    default: [
        {
            type: '50/50',
            rowTier: '2',
            items: [ cards.pi, cards.dosing ],
        },
        {
            type: '50/50',
            rowTier: '2',
            items: [ cards.safety, cards.resources ],
        },
        {
            type: '50/50',
            rowTier: '3',
            items: [ cards.combo, cards['pd-l1'] ],
        },
    ],
};
const tenYearsOfKeytruda = {
    imageUrl: "/static/images/10YearsKeytruda.svg",
    imageAlt:'10 Years of KEYTRUDA® (pembrolizumab)',
    body: `Initial FDA accelerated approval in certain patients with unresectable or metastatic melanoma on <span style="text-wrap:nowrap">September 4, 2014.</span>`,
    url: '/interactivepi/',
    buttonLabel: 'View Prescribing Information',
  };

const Index = ({ location }) => {

    return (
        <FilterProvider>
            <DefaultLayout jobCode={jobCode} pageMeta={pageMeta} location={location}>
                <div data-template-name="template-d">
                    {/* Begin Main Column */}                    

                    <CustomHomepageGrid tieredCtaRows={tieredCTAData.default} heroCtaCustom={tenYearsOfKeytruda} />
                    <PageSection bgColor="gradient">
                        <PrimaryCallOut
                            title="Mechanism of Action"
                            content="See how KEYTRUDA® (pembrolizumab) works."
                            primaryAction={{ label: 'View MOA', url: '/resources/mechanism-of-action/' }}
                        />
                    </PageSection>
                    <PageSection>
                        <PrimaryCallOut
                            title="Ask Merck"
                            contentMaxWidth={500}
                            content="Merck is committed to helping you get your product questions answered. Connect with a Merck Associate now."
                            secondaryAction={{ label: 'Get Connected', url: 'https://www.askmerckoncology.com' }}
                        />
                    </PageSection>
                    <PageSection bgColor="mist">
                        <PrimaryCallOut
                            title="Patient Access & Reimbursement Support"
                            contentMaxWidth={500}
                            content="The Merck Access Program may help answer questions about billing and coding for KEYTRUDA, and co-pay assistance for eligible patients."
                            secondaryAction={{ label: 'Learn About The Merck Access Program', url: 'https://www.merckaccessprogram-keytruda.com/' }}
                        />
                    </PageSection>
                    {/* End Main Column */}
                    <div className="AWS-test-v2"></div>
                </div>
            </DefaultLayout>
        </FilterProvider>
    )
};

export default Index;
